import { InputText } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import type { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { Field } from '../../../components/Field';
import { Row } from '../../../components/Row';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useZipcodeValidator } from '../../../forms/validation/sections/informations/zipcode';
import { CommunesField } from './CommunesField';
import styles from './styles.module.css';

export function DrivingCityScreen(): ReactNode {
  const { change, blur } = useForm();
  const zipcodeValidator = useZipcodeValidator();

  return (
    <ScreenTemplateWithValidation
      fieldNames={['parkingCommune']}
      title={<FormattedMessage id="drivingCityScreen.title" defaultMessage="Dans quelle ville&nbsp;?" />}
      highlightTitle={<FormattedMessage id="drivingCityScreen.content" defaultMessage="Notre conseil d'assureur" />}
      highlightContent={
        <FormattedMessage
          id="drivingCityScreen.highlightContent"
          defaultMessage={
            'Certaines régions sont plus touchées par le vol de voiture.\nPour bien vous protéger contre le vol de votre véhicule, la garantie "Vol" est fortement recommandée.'
          }
        />
      }
    >
      <Row>
        <Field
          autoFocus
          className={styles.Field}
          component={InputText}
          name="parkingCodePostal"
          label={<FormattedMessage id="drivingCityScreen.zipcode" defaultMessage="Code postal" />}
          validate={zipcodeValidator}
          onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
            const { value } = e.target as unknown as { value: string };

            change('parkingCommune', undefined);
            if (value.length === 5) {
              // trigger validation for communesField to get communes from api
              setTimeout(() => blur('parkingCodePostal'), 0);
            }
          }}
        />
        <CommunesField />
      </Row>
    </ScreenTemplateWithValidation>
  );
}
