import { Typography } from '@ornikar/kitt-universal';
import { SubmitButton as ReactFormsSubmitButton } from '@ornikar/react-forms';
import type { ValidationErrors } from 'final-form';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import type { ErrorFieldReport } from '../../hooks/useReportErrorFields';
import { useReportErrorFields } from '../../hooks/useReportErrorFields';

const buildErrorReportArray = (
  errors: ValidationErrors,
  values: Record<string, any>,
  prefix?: string,
): ErrorFieldReport[] => {
  if (!errors) {
    return [];
  }

  const rawErrors = Object.entries(Array.isArray(errors) ? errors.filter(Boolean) : errors);

  return rawErrors.flatMap(([fieldName, error]) => {
    const finalName = prefix ? `${prefix}.${fieldName}` : fieldName;

    const value = values[fieldName] || '';

    if (!error.message?.props?.id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return buildErrorReportArray(error, value, finalName);
    }

    return {
      field_name: finalName,
      field_value: value,
      field_error: error.message.props.id,
    };
  });
};

export interface SubmitButtonProps {
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  stretch?: boolean;
  disabled?: boolean;
}

export function SubmitButton({
  children = 'Continuer',
  stretch,
  isLoading,
  className,
  disabled,
}: SubmitButtonProps): ReactNode {
  const [hasClicked, setHasClicked] = useState(false);
  const reportErrorFields = useReportErrorFields();
  const refReport = useRef(reportErrorFields);
  const { submitting, values, errors, hasValidationErrors, hasSubmitErrors, submitErrors } = useFormState();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && !event.defaultPrevented) {
        event.preventDefault();
        buttonRef.current?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // handle reporting on validation (sync) errors
  useEffect(() => {
    if (hasClicked && hasValidationErrors) {
      refReport.current('validation', buildErrorReportArray(errors, values));
      setHasClicked(false);
    }
  }, [errors, hasClicked, hasValidationErrors, values, refReport]);

  // handle reporting on submit (async) errors
  useEffect(() => {
    if (!submitting && hasSubmitErrors) {
      refReport.current(
        'submit',
        Object.entries(submitErrors || {}).map(([fieldName, error]) => ({
          field_name: fieldName,
          field_value: values[fieldName],
          field_error: error,
        })),
      );
      setHasClicked(false);
    }
  }, [submitting, hasSubmitErrors, submitErrors, values, refReport]);

  return (
    <>
      <button ref={buttonRef} hidden tabIndex={-1} disabled={submitting || disabled} type="submit">
        Submit
      </button>
      <ReactFormsSubmitButton
        disabled={submitting || disabled}
        className={`${className} recapScreenTemplate.submitButton`}
        type="primary"
        icon={isLoading ? 'loading' : null}
        stretch={stretch}
        onClick={() => setHasClicked(true)}
      >
        <Typography.Text color={disabled ? 'grey' : 'white'} variant="bold">
          {children}
        </Typography.Text>
      </ReactFormsSubmitButton>
    </>
  );
}
