import { useFormattedMessage } from '@ornikar/react-intl';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { MaritalStatus } from '../../../apis/types/LongQuoteAnswers';
import { Dropdown } from '../../../components/Dropdown';
import type { FieldProps } from '../../../components/Field';
import { FieldV2 } from '../../../components/FieldV2';

const messages = defineMessages({
  maritalStatusSingle: {
    id: 'driver.details.maritalStatus.alone',
    defaultMessage: 'Je vis seul·e',
  },
  maritalStatusCouple: {
    id: 'driver.details.maritalStatus.couple',
    defaultMessage: 'Je vis en couple',
  },
});

export function MaritalStatusField({ validate }: Pick<FieldProps<string>, 'validate'>): ReactNode {
  const maritalStatusSingle = useFormattedMessage(messages.maritalStatusSingle);
  const maritalStatusCouple = useFormattedMessage(messages.maritalStatusCouple);
  const maritalStatusOptions = useMemo(
    () => [
      {
        label: maritalStatusSingle,
        value: MaritalStatus.Célibataire,
      },
      {
        label: maritalStatusCouple,
        value: MaritalStatus.Marié,
      },
    ],
    [maritalStatusSingle, maritalStatusCouple],
  );

  return (
    <FieldV2
      component={Dropdown}
      className="maritalStatus"
      placeholder={
        <FormattedMessage id="driver.details.maritalStatus.cta" defaultMessage="Choisissez un statut marital" />
      }
      values={maritalStatusOptions}
      label={<FormattedMessage id="driver.details.marital-status.label" defaultMessage="Votre statut marital" />}
      name="primaryDriver.maritalStatus"
      validate={validate}
    />
  );
}
