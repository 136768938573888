import { customProperties } from '@ornikar/kitt/dist/theme-late-ocean';
import { DatePicker } from '@ornikar/kitt-universal';
import type { DayValue, Locale } from '@ornikar/react-modern-calendar-datepicker';
import ModernDatePicker from '@ornikar/react-modern-calendar-datepicker';
import type { LegacyRef, ReactNode } from 'react';
import { useEffect } from 'react';

const customLocale: Locale = {
  // months list by order
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],

  weekDays: [
    {
      name: 'Lundi',
      short: 'L',
    },
    {
      name: 'Mardi',
      short: 'M',
    },
    {
      name: 'Mercredi',
      short: 'M',
    },
    {
      name: 'Jeudi',
      short: 'J',
    },
    {
      name: 'Vendredi',
      short: 'V',
    },
    {
      name: 'Samedi',
      short: 'S',
      isWeekend: true,
    },
    {
      name: 'Dimanche',
      short: 'D',
      isWeekend: true,
    },
  ],

  weekStartingIndex: 6,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit) {
    return digit;
  },

  nextMonth: 'Mois suivant',
  previousMonth: 'Mois précédent',
  openMonthSelector: 'Ouvrir le sélecteur de mois',
  openYearSelector: "Ouvrir le sélecteur d'année",
  closeMonthSelector: 'Fermer le sélecteur de mois',
  closeYearSelector: "Fermer le sélecteur d'année",
  defaultPlaceholder: 'Sélectionner...',

  // for input range value
  from: 'du',
  to: 'au',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

interface CustomDatePickerProps {
  defaultValue?: Date;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onBlur: (value?: Date) => void;
  onChange: (value: Date | undefined) => void;
}

export function CustomDatePicker({
  value,
  defaultValue,
  minDate = new Date('1970-01-01'),
  maxDate = new Date('3000-01-01'),
  onChange,
  onBlur,
}: CustomDatePickerProps): ReactNode {
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  const minimumDate = {
    day: minDate.getDate(),
    month: minDate.getMonth() + 1,
    year: minDate.getFullYear(),
  };

  const selectedDay = value
    ? {
        day: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear(),
      }
    : undefined;

  return (
    <ModernDatePicker
      value={selectedDay}
      calendarPopperPosition="bottom"
      minimumDate={minimumDate}
      maximumDate={{
        day: maxDate.getDate(),
        month: maxDate.getMonth() + 1,
        year: maxDate.getFullYear(),
      }}
      locale={customLocale}
      colorPrimary={customProperties['--kitt-primary-color']}
      colorPrimaryLight={customProperties['--kitt-primary-color']}
      renderInput={({ ref }) => (
        <>
          <input ref={ref as LegacyRef<HTMLInputElement>} hidden defaultValue={value?.toISOString()} />
          <DatePicker
            key={`${value?.toISOString()}`}
            stretch
            value={value}
            placeholder={{ year: 'AAAA', day: 'JJ', month: 'MM' }}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
          />
        </>
      )}
      onChange={(newDay: DayValue) => {
        if (newDay) {
          onChange(
            new Date(
              `${newDay.year}-${newDay.month.toString().padStart(2, '0')}-${newDay.day.toString().padStart(2, '0')}`,
            ),
          );
          onBlur(
            new Date(
              `${newDay.year}-${newDay.month.toString().padStart(2, '0')}-${newDay.day.toString().padStart(2, '0')}`,
            ),
          );
        } else {
          onBlur();
        }
      }}
    />
  );
}
