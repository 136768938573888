import { useFormattedMessage } from '@ornikar/react-intl';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Profession } from '../../../apis/types/LongQuoteAnswers';
import { Dropdown } from '../../../components/Dropdown';
import type { FieldProps } from '../../../components/Field';
import { FieldV2 } from '../../../components/FieldV2';

const messages = defineMessages({
  employedStatus: {
    id: 'driver.details.profession.employedStatus',
    defaultMessage: 'Salarié',
  },
  studentStatus: {
    id: 'driver.details.profession.studentStatus',
    defaultMessage: 'Étudiant',
  },
  professionLiberaleStatus: {
    id: 'driver.details.profession.professionLiberaleStatus',
    defaultMessage: 'Profession libérale',
  },
  entrepreneurStatus: {
    id: 'driver.details.profession.entrepreneurStatus',
    defaultMessage: "Entrepreneur / Chef d'entreprise",
  },
  agriculteurStatus: {
    id: 'driver.details.profession.agriculteurStatus',
    defaultMessage: 'Agriculteur',
  },
  commercantStatus: {
    id: 'driver.details.profession.commercantStatus',
    defaultMessage: 'Commerçant',
  },
  artisanStatus: {
    id: 'driver.details.profession.artisanStatus',
    defaultMessage: 'Artisan',
  },
  fonctionnaireStatus: {
    id: 'driver.details.profession.fonctionnaireStatus',
    defaultMessage: 'Fonctionnaire',
  },
  eclesiastiqueStatus: {
    id: 'driver.details.profession.eclesiastiqueStatus',
    defaultMessage: 'Ecclésiastique',
  },
  vrpStatus: {
    id: 'driver.details.profession.vrpStatus',
    defaultMessage: 'Voyageur, Représentant ou Placier (VRP)',
  },
  retraiteStatus: {
    id: 'driver.details.profession.retraiteStatus',
    defaultMessage: 'Retraité',
  },
  sansProfessionStatus: {
    id: 'driver.details.profession.sansProfessionStatus',
    defaultMessage: 'Sans profession',
  },
});

export function ProfessionField({ validate }: Pick<FieldProps<string>, 'validate'>): ReactNode {
  const employedStatus = useFormattedMessage(messages.employedStatus);
  const studentStatus = useFormattedMessage(messages.studentStatus);
  const professionLiberaleStatus = useFormattedMessage(messages.professionLiberaleStatus);
  const entrepreneurStatus = useFormattedMessage(messages.entrepreneurStatus);
  const agriculteurStatus = useFormattedMessage(messages.agriculteurStatus);
  const commercantStatus = useFormattedMessage(messages.commercantStatus);
  const artisanStatus = useFormattedMessage(messages.artisanStatus);
  const fonctionnaireStatus = useFormattedMessage(messages.fonctionnaireStatus);
  const eclesiastiqueStatus = useFormattedMessage(messages.eclesiastiqueStatus);
  const vrpStatus = useFormattedMessage(messages.vrpStatus);
  const retraiteStatus = useFormattedMessage(messages.retraiteStatus);
  const sansProfessionStatus = useFormattedMessage(messages.sansProfessionStatus);

  const professionOptions = useMemo(
    () => [
      {
        label: employedStatus,
        value: Profession.Salarié,
      },
      {
        label: studentStatus,
        value: Profession.Etudiant,
      },
      {
        label: professionLiberaleStatus,
        value: Profession.ProfessionLibérale,
      },
      {
        label: entrepreneurStatus,
        value: Profession.Entrepreneur,
      },
      {
        label: agriculteurStatus,
        value: Profession.Agriculteur,
      },
      {
        label: commercantStatus,
        value: Profession.Commercant,
      },
      {
        label: artisanStatus,
        value: Profession.Artisan,
      },
      {
        label: fonctionnaireStatus,
        value: Profession.Fonctionnaire,
      },
      {
        label: eclesiastiqueStatus,
        value: Profession.Ecclésiastique,
      },
      {
        label: vrpStatus,
        value: Profession.VRP,
      },
      {
        label: retraiteStatus,
        value: Profession.Retraité,
      },
      {
        label: sansProfessionStatus,
        value: Profession.SansProfession,
      },
    ],
    [
      agriculteurStatus,
      artisanStatus,
      commercantStatus,
      eclesiastiqueStatus,
      employedStatus,
      entrepreneurStatus,
      fonctionnaireStatus,
      professionLiberaleStatus,
      retraiteStatus,
      sansProfessionStatus,
      studentStatus,
      vrpStatus,
    ],
  );

  return (
    <FieldV2
      component={Dropdown}
      label={<FormattedMessage id="driver.details.profession.label" defaultMessage="Votre profession" />}
      name="primaryDriver.profession"
      className="professionField"
      validate={validate}
      values={professionOptions}
      placeholder={<FormattedMessage id="driver.details.profession.cta" defaultMessage="Choisissez une profession" />}
    />
  );
}
