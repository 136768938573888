import cx from 'classnames';
import type { ReactNode } from 'react';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { LocalAutoCompleteInput } from '../../../components/LocalAutocompleteInput';
import { makeChoices } from '../../../fsm/answers';
import { CountryCode, countryCodeToCountryNameMapping } from '../../../utils/country';
import styles from './styles.module.css';

const options = makeChoices(CountryCode, Object.entries(countryCodeToCountryNameMapping)) as {
  label: string;
  value: CountryCode;
}[];

options.sort((a, b) => a.label.localeCompare(b.label));

export interface BirthCountryFieldProps extends Pick<FieldProps<string>, 'className' | 'label' | 'name' | 'validate'> {
  autoComplete: string;
}

export function BirthCountryField({
  className,
  label = 'Votre pays de naissance',
  name,
  validate,
  autoComplete,
}: BirthCountryFieldProps): ReactNode {
  return (
    <Field
      autoComplete={autoComplete}
      choices={options}
      className={cx(className, styles.BirthCountryField, 'birthCountryField')}
      component={LocalAutoCompleteInput}
      label={label}
      name={name}
      placeholder="Sélectionnez un pays"
      validate={validate}
    />
  );
}
