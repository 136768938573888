import { PageLoader as KittLoader, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

export function PageLoader(): ReactNode {
  return (
    <View minHeight="100vh" height="100vh" justifyContent="center" alignItems="center">
      <KittLoader />
    </View>
  );
}
