import { TrashRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, IconButton, View, useBreakpointValue } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import {
  useDateInsurancePeriodValidator,
  useEndDateInsurancePeriodValidator,
} from '../../../../forms/validation/sections/informations/insurancePeriodValidator';
import type { Period } from '../../../../fsm/answers';
import { DateField } from '../../../DateField';
import styles from './styles.module.css';

interface PeriodRowProps {
  fieldName: string;
  idx: number;
  periods: Period[];
  onDelete?: () => void;
}

export function PeriodRow({ fieldName, idx, periods, onDelete }: PeriodRowProps): ReactNode {
  const isMedium = useBreakpointValue({ base: false, medium: true });

  const startFieldName = `${fieldName}[${idx}].start`;
  const endFieldName = `${fieldName}[${idx}].end`;

  const {
    input: { value: start },
  } = useField(startFieldName);

  const forbiddenPeriods = useMemo(() => (periods ? periods.filter((_, i) => i !== idx) : []), [idx, periods]);

  const startDatePeriodValidator = useDateInsurancePeriodValidator(forbiddenPeriods);

  const endDatePeriodValidator = useEndDateInsurancePeriodValidator(start as string, forbiddenPeriods);

  return (
    <div className={styles.PeriodRowContainer}>
      <div className={styles.PeriodRow}>
        <div className={styles.Dates}>
          <DateField name={startFieldName} label="Du" validate={startDatePeriodValidator} />
          <DateField name={endFieldName} label="Au" validate={endDatePeriodValidator} />
        </div>
      </div>
      {onDelete ? (
        <View
          marginTop={{ base: 'kitt.4', medium: 'kitt.12' }}
          width="full"
          flex={1}
          marginLeft={{
            base: 0,
            medium: 'kitt.4',
          }}
        >
          {isMedium ? (
            <IconButton icon={<TrashRegularIcon />} onPress={onDelete} />
          ) : (
            <Button stretch icon={<TrashRegularIcon />} onPress={onDelete}>
              <FormattedMessage
                id="screen.InformationInsurancePeriodsTemplate.PeriodRow"
                defaultMessage="Supprimer l'intervalle"
              />
            </Button>
          )}
        </View>
      ) : null}
    </div>
  );
}
