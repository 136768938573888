import { useComposeValidators, useDateValidator, useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { DateField } from '../../../components/DateField';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { TooltipReleveInformation } from '../../../components/TooltipReleveInformation';
import { useDateBeforeTomorrowValidator } from '../../../forms/validation/sharedValidators';

const fieldName = 'vehicleLastInsuranceEndDate';

export function VehicleLastInsuranceEndDateScreen(): ReactNode {
  const vehicleLastInsuranceEndDateValidator = useComposeValidators(
    useRequiredValidator(),
    useDateValidator(),
    useDateBeforeTomorrowValidator(),
  );

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title="Depuis quand votre véhicule n'est-il pas assuré&nbsp;?"
      highlightTitle="Précision importante"
      highlightContent={
        <div>
          Si le véhicule a déjà été assuré à votre nom: la date de fin d’assurance de votre véhicule se trouve sur votre
          Relevé d’Information <TooltipReleveInformation />.
          <br />
          <br />
          Si le véhicule n’a jamais été assuré à votre nom: entrez la date d’achat du véhicule.
        </div>
      }
    >
      <DateField name={fieldName} validate={vehicleLastInsuranceEndDateValidator} />
    </ScreenTemplateWithValidation>
  );
}
