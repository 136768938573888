import { Typography } from '@ornikar/kitt-universal';
import { Field } from '@ornikar/react-forms';
import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Except } from 'type-fest';
import { suspensionDurationOptionsList } from '../../../../../../constants/mappers/information';
import { Dropdown } from '../../../../../Dropdown';
import type { ScreenTemplateProps } from '../../../../ScreenTemplate';
import { ScreenTemplateWithValidation } from '../../../../ScreenTemplateWithValidation';

interface CancellationRecordSuspendedDurationTemplateProps extends Except<ScreenTemplateProps, 'children'> {
  fieldName: string;
}

export function CancellationRecordSuspendedDurationTemplate({
  fieldName,
  ...props
}: CancellationRecordSuspendedDurationTemplateProps): ReactNode {
  const requiredValidator = useRequiredValidator();

  return (
    <ScreenTemplateWithValidation fieldNames={[fieldName]} {...props}>
      <Typography.Text>
        <Field
          component={Dropdown}
          values={suspensionDurationOptionsList}
          name={fieldName}
          placeholder={
            <FormattedMessage
              id="informationTemplates.cancellationRecordSuspendedDurationTemplate.placeholder"
              defaultMessage="Sélectionner la durée"
            />
          }
          validate={requiredValidator}
        />
      </Typography.Text>
    </ScreenTemplateWithValidation>
  );
}
