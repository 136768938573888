/* eslint-disable no-underscore-dangle */
import type { ID } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Civility } from '../apis/types/LongQuoteAnswers';
import { DriverHasBeenCovered } from '../fsm/answers';
import { useSubscriptionFsmState, useSubscriptionFsmStateValue } from '../fsm/context';
import { State } from '../fsm/types';

declare global {
  interface Window {
    _axcb: unknown[];
  }
}

type AxeptioCookiesName = 'google_ads' | 'google_analytics';
type AxeptioCookiesObject = Record<AxeptioCookiesName, boolean>;

interface Axeptio {
  on: (eventName: string, handler: (choices: AxeptioCookiesObject) => void) => void;
}

const AnalyticsContext = createContext<AnalyticsBrowser>(new AnalyticsBrowser());

const segmentationPageNameToIndex: Partial<Record<State, number>> = {
  [State.FORMULES]: 1,
  [State.OPTIONS_INTRODUCTION]: 2,
  [State.ADDON_DRIVER_PROTECTION_500]: 3,
  [State.REGISTER_IBAN]: 4,
  [State.WEBAPP_INTRODUCTION]: 10,
};

interface AnalyticsProviderProps {
  children: ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps): ReactNode {
  const analytics: AnalyticsBrowser = useMemo(
    () => AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_API_KEY }),
    [],
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}

export function useAnalytics(): AnalyticsBrowser {
  return useContext(AnalyticsContext);
}

const CookieResponseContext = createContext<AxeptioCookiesObject>({} as AxeptioCookiesObject);

export function CookieResponseProvider({ children }: PropsWithChildren): ReactNode {
  const [axeptioResponse, setAxeptioResponse] = useState<AxeptioCookiesObject>({} as AxeptioCookiesObject);

  useEffect(() => {
    window._axcb = window._axcb || [];
    window._axcb.push((axeptio: Axeptio) => {
      axeptio.on('cookies:complete', (choices: AxeptioCookiesObject) => {
        setAxeptioResponse(choices);
      });
    });
  }, []);

  return <CookieResponseContext.Provider value={axeptioResponse}>{children}</CookieResponseContext.Provider>;
}

export function useCookieResponse(): AxeptioCookiesObject {
  return useContext(CookieResponseContext);
}

export function useSegmentAnonymousId(): ID {
  const [anonymousId, setAnonymousId] = useState<ID>();
  const analytics = useAnalytics();

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      const user = await analytics.user();
      setAnonymousId(user.anonymousId());
    };
    getUser();
  }, [analytics]);
  return anonymousId;
}

export function useIdentifyUser(): void {
  const { context } = useSubscriptionFsmState();
  const analytics = useAnalytics();
  const cookies = useCookieResponse();

  useEffect(() => {
    const { segmentUserId, answers, isFromAggregator } = context;

    analytics.identify(segmentUserId, {
      insurance_lead_gender: answers.primaryDriver?.civility === Civility.Femme ? 'F' : 'M',
      insurance_lead_penaly:
        answers.primaryDriver?.hasBeenCovered === DriverHasBeenCovered.NonJamais ? 100 : answers.primaryDriver?.crm,
      insurance_lead_is_from_aggregator: isFromAggregator,

      ad_storage: cookies.google_ads,
      ad_user_data: cookies.google_ads,
      ad_personalization: cookies.google_ads,
      analytics_storage: cookies.google_analytics,
    });
  }, [analytics, context, cookies]);
}

export function useSendPageNameToSegment(): void {
  const analytics = useAnalytics();
  const pageName = useSubscriptionFsmStateValue() as State;
  const cookies = useCookieResponse();

  useEffect(() => {
    analytics.page('INS Subscription', pageName, {
      insLeadStep: segmentationPageNameToIndex[pageName],
      ad_storage: cookies.google_ads,
      ad_user_data: cookies.google_ads,
      ad_personalization: cookies.google_ads,
      analytics_storage: cookies.google_analytics,
    });
  }, [analytics, pageName, cookies]);
}
