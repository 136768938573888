import sha256 from 'crypto-js/sha256';
import mixpanel from 'mixpanel-browser';
import { SubscribeStatus } from '../../apis/types';
import { PaymentPeriodicity } from '../../apis/types/PaymentOptions';
import type { TarificationResultOK } from '../../apis/types/Quotation';
import {
  sendFailedInsuranceLongQuoteGTMEvent,
  sendFillFormInsuranceLongQuoteGTMEvent,
  sendInitiateCheckoutPaymentEvent,
  sendPaymentSuccessEvent,
  sendSuccessInsuranceLongQuoteGTMEvent,
  sendSumUpInsuranceLongQuoteGTMEvent,
  sendWelcomePageReachedEvent,
} from '../../utils/dataLayer';
import { sendEvent } from '../../utils/mixpanel';
import { postReactNativeMessage } from '../../utils/nativeApp';
import { getSelectedOptionsFromAnswers } from '../../utils/options';
import type { InsuranceFsmConfig, PaymentEvent, State } from '../types';
import { isTarificationOK } from '../types';

// This enum represents all messages that are sent from web to native. It comes from webviewTypes file in insurance-selfcare-webapp.
enum MessageKeys {
  ON_ROUTE_CHANGE = 'onRouteChange',
  ON_AUTH_ERROR = 'onAuthError',
  ON_LOGOUT = 'onLogout',
  ON_UPDATE_POST_SIGN_IN_ACTIONS = 'onUpdatePostSignInActions',
  ON_POLICY_SUBSCRIBED = 'onPolicySubscribed',
  ON_LONGQUOTE_OK = 'onLongquoteOk',
}

const mapPeriodicityForTracking: Record<PaymentPeriodicity, string> = {
  [PaymentPeriodicity.ANNUALLY]: 'annual',
  [PaymentPeriodicity.MONTHLY]: 'monthly',
};

const goNoGo = (ok: boolean | undefined): string => (ok ? 'Go' : 'NoGo');

// TODO[>1]: replace by the new young_driver product
export const isProduitJeune = (produit: string): boolean => produit === 'AUTO MDE' || produit === 'AUTO AXERIA';

export const getAnalyticsConfig = (): InsuranceFsmConfig => ({
  actions: {
    sendUserConfirmsAnswers: sendSumUpInsuranceLongQuoteGTMEvent,
    sendUserFillsForm: (context) => sendFillFormInsuranceLongQuoteGTMEvent(context.answers.subscriberEmail!),
    sendUserWaitsForLongQuote: (context) => {
      sendEvent('Waiting For Insurance Long Quote');
    },
    sendHamonLawScreenDisplayed: (context, event) => {
      const res = context.tarificationResult!;

      sendEvent('Complete Insurance Long Quote', {
        long_quote_id: res.quoteId,
        lead_quote_status: 'hamon_law',
      });
    },
    sendUserCompletesLongQuote: (context) => {
      const res = context.tarificationResult!;

      const quoteStatus = goNoGo(res.isOk);

      sendEvent('Complete Insurance Long Quote', {
        long_quote_id: res.quoteId,
        lead_quote_status: quoteStatus,
        nogo_reason: res.isOk ? undefined : res.error,
        category_quoted_tiers: isTarificationOK(res)
          ? isProduitJeune(res.packages[0].product)
            ? 'jeune_conducteur'
            : 'autre'
          : undefined,
        isValidationError: (!res.isOk && res.isValidationError) || undefined,
      });

      if (isTarificationOK(res)) {
        sendSuccessInsuranceLongQuoteGTMEvent({
          hasEducationAccount: Boolean(
            context.tarificationResult?.isOk && context.tarificationResult.hasEducationAccount,
          ),
          hasDiscount: Boolean(context.tarificationResult?.isOk && context.tarificationResult.hasDiscount),
          email: context.answers.subscriberEmail!,
        });

        const firstFormula = res.packages[0];
        const leadId = localStorage.getItem('leadId');

        postReactNativeMessage(
          MessageKeys.ON_LONGQUOTE_OK,
          JSON.stringify({
            insurance_quote_id: res.quoteId,
            // marketing team wants the lowest annual price product & name. I assume first element of formules array is Tiers
            insurance_product: firstFormula.product,
            insurance_formula: firstFormula.name,
            email: sha256(context.answers.subscriberEmail!),
            phone: sha256(context.answers.subscriberPhone!),
            lead_id: leadId,
            mixpanel_id: mixpanel.get_distinct_id(),
            // marketing team wants the lowest annual price. I assume first element of formules array is Tiers
            insurance_annual_price: firstFormula.yearlyPrice.cents,
          }),
        );
      } else {
        sendFailedInsuranceLongQuoteGTMEvent(context.answers.subscriberEmail);
      }
    },
    sendUserCustomizeInsuranceContract: (context) => {
      const pickedFormule = context.formulePickedObject!;
      const tarificationResult = context.tarificationResult as TarificationResultOK;
      sendEvent('Customize Insurance Contract', {
        long_quote_id: tarificationResult.quoteId,
        warranties: context.answers.optionsSelected,
        product_type: pickedFormule.code,
        product_name: isProduitJeune(pickedFormule.product) ? 'jeune_conducteur' : 'autre',
      });
    },
    /**
     * NOTTINGHAM SPECIFIC
     */
    sendUserPaymentAttemptDeclined: (context, event) => {
      const { conversionValue } = event as PaymentEvent;
      const { periodicity } = context.answers;

      sendEvent('Payment Attempt', {
        deposit_payment_method: 'CB',
        deposit_payment_status: 'declined',
        recurring_payment_method: 'IBAN',
        recurring_payment_periodicity: periodicity ? mapPeriodicityForTracking[periodicity] : '',
      });

      sendInitiateCheckoutPaymentEvent(conversionValue);
    },
    sendUserPaymentAttemptValidated: (context, event) => {
      const { conversionValue } = event as PaymentEvent;
      const { periodicity } = context.answers;

      sendEvent('Payment Attempt', {
        deposit_payment_method: 'CB',
        deposit_payment_status: 'validated',
        recurring_payment_method: 'IBAN',
        recurring_payment_periodicity: periodicity ? mapPeriodicityForTracking[periodicity] : '',
      });

      sendInitiateCheckoutPaymentEvent(conversionValue);
    },
    /**
     * NOTTINGHAM SPECIFIC END
     */
    sendUserClosesNewOpportunityOnline: (context, event) => {
      const { conversionValue } = event as PaymentEvent;
      const formule = context.formulePickedObject!;
      const tarificationResult = context.tarificationResult as TarificationResultOK;
      const subscriptionResult = context.subscribeResult;

      sendEvent('New Subscription Online', {
        product_type: formule.code,
        long_quote_id: tarificationResult.quoteId,
        warranties: context.answers.optionsSelected,
        start_date: new Date(context.answers.dateEffetSouhaite!).toISOString().split('T')[0],
        product_name: isProduitJeune(formule.product) ? 'jeune_conducteur' : 'autre',
        // TODO (@goldiggy) [>1]: handle contract_id with Guidewire
        contract_id:
          subscriptionResult && subscriptionResult.status !== SubscribeStatus.KO
            ? subscriptionResult.contractId
            : undefined,
        options_selected: getSelectedOptionsFromAnswers(context.answers),
      });

      sendWelcomePageReachedEvent(context.answers.subscriberEmail);

      const leadId = localStorage.getItem('leadId');

      postReactNativeMessage(
        MessageKeys.ON_POLICY_SUBSCRIBED,
        JSON.stringify({
          insurance_quote_id: tarificationResult.quoteId,
          insurance_product: formule.product,
          insurance_formula: formule.name,
          email: sha256(context.answers.subscriberEmail!),
          phone: sha256(context.answers.subscriberPhone!),
          lead_id: leadId,
          mixpanel_id: mixpanel.get_distinct_id(),
          insurance_annual_price: formule.yearlyPrice.cents,
        }),
      );

      sendPaymentSuccessEvent(conversionValue, context.answers.subscriberEmail);
    },
  },
  guards: {},
});

export const sendBreadcrumbDestinationStateEvent = (state: State): void => {
  sendEvent('Click on Navigation Thread', {
    destination_state: state,
  });
};
