import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { useField } from 'react-final-form';
import type { Except } from 'type-fest';
import type { FieldProps } from '../Field';
import { FieldV2 } from '../FieldV2';
import type { DateConverterProps } from './DateConverter';
import { DateConverter } from './DateConverter';

export type DateFieldProps = Except<FieldProps<string, DateConverterProps>, 'type' | 'component'>;

export function DateField(props: DateFieldProps & { name: string }): ReactNode {
  const {
    input: { onChange: onFieldChange, onBlur },
    meta: { touched },
  } = useField<string>(props.name);

  const onChange = useCallback(
    (value: string) => {
      onFieldChange(value);

      // eslint-disable-next-line security/detect-unsafe-regex, unicorn/no-unsafe-regex
      if (touched || /[0-9]{4}(-[0-9]{2}){2}/.test(value)) {
        onBlur();
      }
    },
    [touched, onFieldChange, onBlur],
  );

  return (
    <FieldV2<string, DateConverterProps>
      {...props}
      formatOnBlur
      type="date"
      placeholder={{ day: 'JJ', month: 'MM', year: 'AAAA' }}
      component={DateConverter}
      format={(value: string) => {
        if (value) {
          const [year, month, day] = value.split('-');
          return `${year}-${month ? month.padStart(2, '0') : ''}-${day ? day.padStart(2, '0') : ''}`;
        }

        return value;
      }}
      onChange={onChange}
    />
  );
}
