import { Typography } from '@ornikar/kitt-universal';
import type { FieldProps as FormFieldProps, ValidationStatus } from '@ornikar/react-forms';
import { Field as FormField } from '@ornikar/react-forms';
import cx from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

// @TODO: figure out a way not to have to copy paste this
export type FieldProps<FieldValue, P = Record<string, never>> = FormFieldProps<FieldValue, P> &
  // eslint-disable-next-line @typescript-eslint/ban-types
  Omit<
    P,
    // we cannot use keyof since https://github.com/final-form/react-final-form/commit/f3c1aea93b520c0620f1b8090f5966e0da94a798#diff-610dfe348b754acef30bbf367286111dR28
    // keyof FieldRenderProps<FieldValue, any>['input']
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'type'
    | 'value'
    | 'checked'
    | 'multiple'
    // id is defaulted to name
    | 'id'
  > &
  Partial<Pick<P, Extract<'onChange' | 'onFocus' | 'onBlur', keyof P>>> & {
    testID?: string;
  };

export function FieldV2<FieldValue, P = Record<string, never>>(
  props: PropsWithChildren<FieldProps<FieldValue, P>>,
): ReactNode {
  const { getFieldState } = useForm();

  const [isInitial, setIsInitial] = useState(true);

  const [errorMessageOverride, setErrorMessageOverride] = useState<ValidationStatus>();

  const field = getFieldState(props.name);

  useEffect(() => {
    if (isInitial && field?.initial && props.validate) {
      const validation = props.validate(field.initial as FieldValue, []);

      Promise.resolve(validation).then((result) => {
        if (result) {
          setErrorMessageOverride({
            state: 'invalid',
            feedback: <Typography.Text>{result.message}</Typography.Text>,
          });
        }
      });

      setIsInitial(false);
    }
  }, [field?.initial, isInitial, props]);

  return (
    <FormField
      {...props}
      label={props.label ? <Typography.Text>{props.label}</Typography.Text> : undefined}
      validationStatusOverride={props.validationStatusOverride || errorMessageOverride}
      className={cx(props.className)}
      validate={(v, vs) => {
        setErrorMessageOverride(undefined);
        if (props.validate) {
          return props.validate(v, vs);
        }

        return undefined;
      }}
    />
  );
}
