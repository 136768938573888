import { CalendarRegularIcon, PaperPlaneTiltRegularIcon, PhoneRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, CardModal, HStack, Highlight, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useContactModal, useSetContactModal } from '../../contexts/contactModal';
import { useSubscriptionFsmState } from '../../fsm/context';
import { State } from '../../fsm/types';
import { CallbackModuleModal } from '../CallbackModuleModal';

const isPostQuote = (state: State): boolean =>
  [
    State.FORMULES,
    State.HAMON_LAW_INFORMATION,
    State.REGISTER_IBAN,
    State.CONFIRM_SEPA_MANDATE,
    State.CREDIT_CARD_PAYMENT,
    State.VEHICLE_LEGAL_INFORMATION,
    State.LRE_INFORMATION,
    State.WEBAPP_INTRODUCTION,
  ].includes(state);

export function ContactModal(): ReactNode {
  const visible = useContactModal();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const setContactModal = useSetContactModal();
  const { value: currentState } = useSubscriptionFsmState();

  return (
    <>
      <CardModal.ModalBehaviour visible={visible} zIndex={0} onClose={() => setContactModal(false)}>
        <CardModal>
          <CardModal.Header>
            <Typography.Text variant="bold">
              <FormattedMessage id="contactModal.title" defaultMessage="Contact" />
            </Typography.Text>
          </CardModal.Header>
          <CardModal.Body>
            <View marginBottom={{ base: 'kitt.3', medium: 'kitt.6' }}>
              <Typography.Text base="header4">
                <FormattedMessage
                  id="contactModal.subtitle"
                  defaultMessage="Un doute&nbsp;? Une question&nbsp;? Bavardons&nbsp;!"
                />
              </Typography.Text>
            </View>
            <Highlight>
              <VStack space="kitt.4">
                <Typography.Text textAlign="center">
                  <FormattedMessage
                    id="contactModal.text"
                    defaultMessage="Sophie, Mamadou et tous nos conseiller•e•s sont à votre disposition du lundi au samedi entre 10h et 19h."
                  />
                </Typography.Text>
                <HStack flexWrap="wrap" justifyContent="center">
                  <View padding="kitt.1">
                    <Button
                      stretch={{ base: true, medium: false }}
                      type="primary"
                      icon={<PhoneRegularIcon />}
                      href="tel:0176497522"
                    >
                      01 76 49 75 22
                    </Button>
                  </View>
                  {isPostQuote(currentState as State) && (
                    <View padding="kitt.1">
                      <Button
                        stretch={{ base: true, medium: false }}
                        type="primary"
                        icon={<CalendarRegularIcon />}
                        onPress={() => {
                          setContactModal(false);
                          setIsModalVisible(true);
                        }}
                      >
                        <FormattedMessage id="contactModal.makeAppointment" defaultMessage="Prendre un rendez-vous" />
                      </Button>
                    </View>
                  )}
                  <View padding="kitt.1">
                    <Button
                      stretch={{ base: true, medium: false }}
                      type="primary"
                      icon={<PaperPlaneTiltRegularIcon />}
                      href="mailto:assurance@ornikar.com"
                    >
                      assurance@ornikar.com
                    </Button>
                  </View>
                </HStack>
              </VStack>
            </Highlight>
          </CardModal.Body>
        </CardModal>
      </CardModal.ModalBehaviour>
      <CallbackModuleModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </>
  );
}
