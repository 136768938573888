import { ExternalLink, TypographyLink } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

// TODO [>1]: make it an env var
const GOCARDLESS_PRIVACY_POLICY_URL = 'https://gocardless.com/fr-fr/legal/politique-de-confidentialite/';

interface GoCardlessPrivacyPolicyLinkProps {
  children: NonNullable<ReactNode>;
}

export function GoCardlessPrivacyPolicyLink({ children }: GoCardlessPrivacyPolicyLinkProps): ReactNode {
  return (
    <ExternalLink as={TypographyLink} color="black-light" href={GOCARDLESS_PRIVACY_POLICY_URL} variant="regular">
      {children}
    </ExternalLink>
  );
}
