import type { DatePickerProps } from '@ornikar/kitt-universal';
import { DatePicker } from '@ornikar/kitt-universal';
import { format } from 'date-fns';
import { type ReactNode } from 'react';
import type { Except } from 'type-fest';
import { useInsuranceDesktopMediaQuery } from '../../hooks/useInsuranceDesktopMediaQuery';
import { CustomDatePicker } from '../CustomDatePicker';

function dateToString(value: Date | undefined): string {
  if (value) {
    return format(value, 'yyy-MM-dd');
  }
  return '';
}

export type DateConverterProps = Except<DatePickerProps, 'onChange' | 'onBlur'> & {
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
};

export function DateConverter({
  value: unsafeValue,
  onChange,
  onBlur,
  ...convertedProps
}: DateConverterProps): ReactNode {
  const isDesktop = useInsuranceDesktopMediaQuery();

  const newOnChange = (value: Date | undefined): void => {
    onChange(value ? dateToString(value) : '');
  };

  const newOnBlur = (newValue: Date | undefined): void => {
    if (newValue) {
      newOnChange(newValue);
    }
    if (onBlur) {
      onBlur(newValue ? dateToString(newValue) : '');
    }
  };

  const convertedValue = unsafeValue ? new Date(unsafeValue ?? '') : undefined;

  if (isDesktop) {
    return <CustomDatePicker {...convertedProps} value={convertedValue} onChange={newOnChange} onBlur={newOnBlur} />;
  }
  return <DatePicker stretch {...convertedProps} value={convertedValue} onChange={newOnChange} onBlur={newOnBlur} />;
}
