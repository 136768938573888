import { InfoFillIcon } from '@ornikar/kitt-icons/phosphor';
import type { TooltipProps, TypographyProps } from '@ornikar/kitt-universal';
import { Tooltip, Typography, TypographyIcon, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';

export interface InfoTooltipProps {
  position: TooltipProps['position'];
  tooltip: TooltipProps['content'];
  color?: TypographyProps['color'];
}

export function InfoTooltip({ position, tooltip, color = 'black-light' }: InfoTooltipProps): ReactNode {
  return (
    <View width="fit-content">
      <Tooltip content={<Typography.Text color="kitt.white">{tooltip}</Typography.Text>} position={position}>
        <TypographyIcon align="center" icon={<InfoFillIcon height={24} width={24} />} color={color} />
      </Tooltip>
    </View>
  );
}
