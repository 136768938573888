import { Checkbox, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { FieldV2 } from '../../../../components/FieldV2';
import { useIbanCheckboxValidator } from '../../../../forms/validation/sections/insurance/iban';
import { useDocumentsNeeded } from '../../../../hooks/useDocumentsNeeded';

const CHECKBOXES_LEGAL_VERSION = 7;

export function PaymentLegalCheckboxes(): ReactNode {
  const { checkboxesVersionInput } = useField('checkboxesVersion');
  const documentsList = useDocumentsNeeded();

  const validCheckBox = useIbanCheckboxValidator();

  useEffect(() => {
    checkboxesVersionInput?.onChange(CHECKBOXES_LEGAL_VERSION);
  }, [checkboxesVersionInput]);

  /* LEGAL REQUIREMENT : any change in the following return block shoud increment the CHECKBOXES_LEGAL_VERSION */

  return (
    <VStack alignItems="stretch" space="kitt.4">
      <FieldV2
        name="legalCheckbox1"
        className="legalCheckbox1"
        validate={validCheckBox}
        type="checkbox"
        component={Checkbox}
      >
        <Typography.Text>
          Je reconnais avoir pris connaissance des documents d'information fournis par l'Assureur (FIC et DIPA), des
          Conditions Générales Automobile, de la recommandation de Ornikar Assurances et du délai de 14 jours
          calendaires à compter de la souscription du contrat pour me rétracter.
        </Typography.Text>
      </FieldV2>
      <FieldV2
        name="legalCheckbox2"
        className="legalCheckbox2"
        validate={validCheckBox}
        type="checkbox"
        component={Checkbox}
      >
        <Typography.Text>
          Je reconnais que l'ensemble des déclarations apportées aux questions précédemment posées sont exactes,
          conformes à la réalité et au risque objet de mon souhait d'assurance et je m'engage à transmettre tous les
          documents nécessaires (
          {documentsList
            .map((document) => (document === 'Permis de conduire' ? `${document} ou certificat temporaire` : document))
            .join(', ')}
          ) à la finalisation de mon contrat sous 30 jours.
        </Typography.Text>
      </FieldV2>
      <VStack marginTop="kitt.3">
        <Typography.Paragraph color="black-light" base="body-xsmall">
          En souscrivant un contrat, vous êtes susceptible de recevoir des offres et avantages exclusifs du groupe
          Ornikar. Vous pourrez vous désinscrire à tout moment.
        </Typography.Paragraph>
      </VStack>
    </VStack>
  );
  /* END LEGAL REQUIREMENT */
}
